/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

html,
body {
  min-height: 100%;
}

body {
  background: #c9c9c7;
  color: $white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.01em;
  line-height: 2em;
  text-transform: none;
}

*:not(h1):not(h2):not(h3) {
  word-wrap: break-word;
}

.header {
  min-height: 100vh;
  padding: 30px 50px;

  .background-image {
    height: 160vh;
    width: 100vw;

    div {
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }

  p {
    a {
      color: #fff;
      transition: color 0.15s ease-out;
      padding-bottom: 0.05em;
      text-decoration: none;

      transition: border-color 0.15s ease-out, color 0.15s ease-out;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: rgba(255, 255, 255, 0.3);

      &:hover {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.6);
        border-color: rgba(255, 255, 255, 0.15);
      }
    }
  }

  .react-parallax-bgimage {
    opacity: 0.6;
  }

  .react-parallax-background-children {
    top: calc(-50% + 350px);

    &:after {
      content: " ";
      position: fixed;
      background: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .container-box {
    padding: 30px 50px;
  }

  .carousel-item {
    max-height: 246px;
  }

  // .react-parallax-bgimage:after {
  //   content: " ";
  //   background-color: #000;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   opacity: 0.8;
  // }
}

.social-media {
  text-align: center;

  &:hover {
    svg {
      fill: rgba(255, 255, 255, 0.4);
    }
  }

  svg {
    height: 56px;
    fill: #fff;
    transition: fill 170ms ease-in-out;

    &:hover {
      fill: #fff;
    }
  }
}
